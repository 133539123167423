<template>
    <div id="asset-cluster-page">
        <toolbar-component title="Mes bâtiments"/>

        <div class="pa-4">
            <template v-if="!loading_asset_clusters">
                <template v-if="asset_clusters.length > 0">
                    <v-text-field v-model="search" :label="$t('global.search')" class="white-text-field mb-2"
                                  filled hide-details prepend-inner-icon="mdi-magnify"
                    />

                    <p class="font-weight-bold mb-4">
                        Vous avez {{ asset_clusters.length }} bâtiment(s)
                    </p>

                    <v-row>
                        <v-col v-for="asset_cluster in asset_clusters" :key="asset_cluster.id_asset_cluster"
                               class="mt-2" cols="12" lg="6"
                        >
                            <v-card class="elevation-0 fill-height">
                                <v-card-title class="pb-0">
                                    <v-row>
                                        <v-col cols="11">
                                            <span class="font-16 font-color-medium">Nom du bâtiment</span>
                                            <p class="mb-1 font-24 primary--text">
                                                {{ asset_cluster.name }}
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <menu-actions-component :actions="[{
                                                                title: 'Voir le détail',
                                                                icon: 'mdi-eye-outline',
                                                                emit: 'goToAssetClusterDetails',
                                                                has_access: true
                                                            }]"
                                                            v-on:goToAssetClusterDetails="$router.push('/asset_cluster/' + asset_cluster.id_asset_cluster)"
                                    />
                                </v-card-title>

                                <v-card-text>
                                    <v-row class="mt-0">
                                        <v-col>
                                            <v-divider class="pb-2"/>
                                        </v-col>
                                    </v-row>

                                    <v-row class="pb-4 font-16">
                                        <v-col class="py-0">
                                            <span class="font-color-medium">Volume en kg</span>
                                            <p class="pt-1 mb-0">
                                               <span class="font-weight-bold">
                                                   {{ numberWithSpaces(asset_cluster.volume_actual) }}
                                               </span>
                                                sur
                                                <span class="font-weight-bold">
                                                   {{ numberWithSpaces(asset_cluster.volume_total) }}
                                               </span>
                                            </p>
                                        </v-col>

                                        <v-divider color="white" vertical/>

                                        <v-col class="py-0">
                                            <span class="font-color-medium">Consommation (Kg/j)</span>
                                            <p class="pt-1 mb-0 font-weight-bold">
                                                {{ numberWithSpaces(asset_cluster.consumption) }}
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row class="ma-0 mt-2">
                                        <span class="font-color-medium mb-2">
                                            Projection sur les {{ asset_cluster.chart.period }} prochains jours
                                        </span>

                                        <chart-before-break-consumption-component :asset_cluster="asset_cluster"/>
                                    </v-row>

                                    <v-row class="pb-2 font-16">
                                        <v-col class="py-0">
                                            <span class="font-color-medium">Jours avant 27 T</span>
                                            <p class="pt-1 mb-0 font-weight-bold">
                                                {{ asset_cluster.nb_day_before_27t }}
                                            </p>
                                        </v-col>

                                        <v-divider color="white" vertical/>

                                        <v-col class="py-0">
                                            <span class="font-color-medium">Jours avant rupture</span>
                                            <p class="pt-1 mb-0 font-weight-bold">
                                                {{ asset_cluster.nb_day_before_failure }}
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row class="font-16">
                                        <v-col>
                                            <v-divider class="pb-4"/>

                                            <span class="font-color-medium">Silos du bâtiment</span>

                                            <template v-for="asset in asset_cluster.assets">
                                                <v-row :key="asset.id_asset" class="mt-2">
                                                    <v-col cols="11" class="pt-0">
                                                        <p class="mb-0 font-weight-bold">
                                                            {{ asset.name }}
                                                        </p>
                                                        <span class="font-color-medium">
                                                           {{ asset.type_asset_label }}
                                                       </span>
                                                    </v-col>

                                                    <v-col cols="1" class="display-flex-content-center">
                                                        <v-icon class="pointer font-32 pr-2">mdi-chevron-right</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>

                <template v-else>
                    <empty-page-component
                        :link_img="'/images/error/empty_page.png'"
                        :main_text="'Vous n\'avez pas de bâtiment configuré pour le moment'"
                        :secondary_text="'Pour configurer un bâtiment veuillez cliquer sur le bouton +'"
                        :show_secondary_text="userHasAccess('BAT', role_admin)"
                    />
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import ChartBeforeBreakConsumptionComponent from "../../components/Widget/AssetCluster/ChartBeforeBreakConsumptionComponent";
import EmptyPageComponent from "../../components/Error/EmptyPageComponent";
import ToolbarComponent from "../../components/Global/ToolbarComponent";
import MenuActionsComponent from "../../components/Global/MenuActionsComponent";

export default {
    name: "AssetClusterPage",
    components: {ChartBeforeBreakConsumptionComponent, EmptyPageComponent, MenuActionsComponent, ToolbarComponent},
    data() {
        return {
            loading_asset_clusters: true,
            search: ''
        }
    },
    computed: {
        asset_clusters: {
            get: function () {
                return this.$store.getters['asset_cluster/asset_clusters']
            },
            set: function (value) {
                this.$store.dispatch('asset_cluster/getClientAssetClusters', this.$store.getters['user_connected/user_connected'].id_client)
            },
        },
    },
    created() {
        this.getClientAssetClusters();
    },
    methods: {
        getClientAssetClusters() {
            this.loading_asset_clusters = true;

            this.$store.dispatch('asset_cluster/getClientAssetClusters', {
                id_client: this.$store.getters['user_connected/user_connected'].id_client
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_asset_clusters = false
            });
        }
    }
}
</script>
