<template>
    <div id="chart-before-break-consumption-component ma-0" class="w-100">
        <highcharts :options="chart_options"/>
    </div>
</template>

<script>
export default {
    name: "ChartBeforeBreakConsumption",
    props: {
        asset_cluster: {
            type: Object,
            require: true,
        }
    },
    data() {
        return {
            chart_options: {
                chart: {
                    type: 'column',
                    width: null
                },
                lang: {
                    noData: this.$t('error_display.no_data'),
                },
                time: {
                    timezone: 'Europe/Paris',
                },
                title: {
                    text: null
                },
                legend: {
                    align: 'right',
                    floating: true,
                    verticalAlign: 'top'
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    gridLineWidth: null,
                    labels: {
                        formatter: (value) => {
                            let legend = value.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                            if(this.is_mobile) {
                                legend =  value.axis.defaultLabelFormatter.call(value);
                            }
                            return legend;
                        }
                    },
                    lineWidth: 1,
                    min: 0,
                    title: {
                        text: null
                    }
                },
                plotOptions: {
                    column: {
                        borderColor: null,
                        stacking: 'normal',
                    }
                },
                tooltip: {
                    shared: true
                },
                series: [{
                    name: 'Creux',
                    data: [],
                    borderRadiusTopLeft: '50px',
                    borderRadiusTopRight: '50px'

                }, {
                    name: 'Commande',
                    data: []

                }, {
                    name: 'Volume',
                    data: []

                }]
            }
        }
    },
    created() {
        this.chart_options.plotOptions.column.pointWidth = (this.is_mobile ? '15' : '40');
        this.buildChart();
    },
    methods: {
        buildChart() {
            this.chart_options.series[0].color = this.asset_cluster.chart.color_hollow;
            this.chart_options.series[1].color = this.asset_cluster.chart.color_command;
            this.chart_options.series[2].color = this.asset_cluster.chart.color_level;

            this.asset_cluster.chart.data.forEach((data) => {
                let date = this.moment(data.date).locale(this.language).format(this.asset_cluster.chart.format_date);
                this.chart_options.xAxis.categories.push(date);

                this.chart_options.series[0].data.push(data.hollow * 1000);
                this.chart_options.series[1].data.push(data.command * 1000);
                this.chart_options.series[2].data.push(data.level * 1000);
            });
        }
    }
}
</script>

<style>
.highcharts-credits {
    visibility: hidden;
}
</style>